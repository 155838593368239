import React from "react";
import { ExperiencesList } from "../../../data/ExperienceData";
import {
  Card,
  CardLeft,
  CardRight,
  TechCardContainer,
  TechCard,
  BtnGroup,
} from "./ExperienceCardElements";
import ScrollAnimation from "react-animate-on-scroll";
function ExperienceCard() {
  return (
    <>
      {ExperiencesList.map((list, index) => (
        <ScrollAnimation animateIn="fadeInLeft" key={index}>
          <Card>
            <CardLeft>
              <img src={list.img} alt={list.company} />
            </CardLeft>
            <CardRight>
              <h4>{list.title}</h4>
              <ul>
                {list.description.map((desc, idx) => (

                  < li value={idx} key={idx}> {desc} </li>
                ))}
              </ul>
              <TechCardContainer>
                {list.tech_stack.map((tech, index) => (
                  <TechCard key={index}>{tech}</TechCard>
                ))}
              </TechCardContainer>
              <BtnGroup>
                {list.company_site.length > 0 && (
                  <a
                    className="btn GithubBtn btn-shadow"
                    href={list.company_site}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {list.company} ➜
                  </a>
                )}
              </BtnGroup>
            </CardRight>
          </Card>
        </ScrollAnimation >
      ))
      }
    </>
  );
}

export default ExperienceCard;
