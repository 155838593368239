// --------------------------------------- Project List
export const ProjectList = [

  {
    img: "siteimages/PSSE.png",
    short_name: "PSSE",
    title: "Python Simple Search Engine [PSSE]",
    description:
      "Search Engine implementation based on BM25 and Inverted Matrixes.",
    tech_stack: ["Python"],
    github_url: "https://github.com/sgonzalezr94/PythonSimpleSearchEngine",
    demo_url: "",
  },

  {
    img: "siteimages/PPS.png",
    short_name: "PPS",
    title: "Python Proxy Switcher[PPS]",
    description:
      "A simple HTTP, lightweight Python proxy switcher.",
    tech_stack: ["Python", "pipenv"],
    github_url: "https://github.com/sgonzalezr94/python-proxy-switcher",
    demo_url: "",
  },

  {
    img: "siteimages/SpotifyBot.png",
    short_name: "SpotifyBot",
    title: "Spotify Looping Bot",
    description:
      "A selenium bot which reproduces songs in Spotify and increases heard count for any given artist.",
    tech_stack: ["Python", "Selenium", "Spotify"],
    github_url: "https://github.com/sgonzalezr94/SpotifyLoopingBot",
    demo_url: "",
  },
];

// --------------------------------------- Skills

export const stackList = [
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
    name: "Python",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/flask/flask-original.svg",
    name: "Flask",
  },
  {
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/django/django-plain.svg",
    name: "django",
  },
  {
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/fastapi/fastapi-plain.svg",
    name: "FastAPI",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original.svg",
    name: "MySQL",
  },
  {
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/postgresql/postgresql-original.svg",
    name: "Postgresql",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original.svg",
    name: "MongoDB",
  },
  {
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/dynamodb/dynamodb-original.svg",
    name: "DynamoDB",
  },
  {
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/redis/redis-original.svg",
    name: "Redis",
  },
  {
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/amazonwebservices/amazonwebservices-original-wordmark.svg",
    name: "AWS",
  },
  {
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/jenkins/jenkins-original.svg",
    name: "Jenkins",
  },
  {
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/apachekafka/apachekafka-original.svg",
    name: "Apache Kafka",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg",
    name: "NodeJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/git/git-original.svg",
    name: "Git",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg",
    name: "HTML",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg",
    name: "JavaScript",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
    name: "ReactJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
    name: "Typescript",
  },

  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/vscode/vscode-original.svg",
    name: "VS Code",
  },
];
