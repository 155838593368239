import React, { useState } from "react";
import { ContactWrapper, Email, Resume } from "./ContactElements";
import { MdContentCopy } from "react-icons/md";
import { IconButton, Tooltip } from "@mui/material";
import Zoom from '@mui/material/Zoom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import ScrollAnimation from "react-animate-on-scroll";
function Contact() {
  const [showTooltip, setShowTooltip] = useState(false);
  const copyToClipboard = () => {
    navigator.clipboard.writeText("sebastiang1493@gmail.com");
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 700);
  };

  return (
    <ContactWrapper id="contact">

      <div className="Container">
        <div className="SectionTitle">Get In Touch</div>
        <ScrollAnimation animateIn="fadeIn" >
          <div className="BigCard">
            <Email>
              <div style={{ display: 'flex', alignItems: 'center', columnGap: '20px', rowGap: '10px', flexWrap: 'wrap', justifyContent: 'center' }} >
                <span>sebastiang1493@gmail.com</span>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  open={showTooltip}
                  onClose={() => setShowTooltip(false)}
                  title="Copied!"
                  TransitionComponent={Zoom}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  placement="bottom"
                >
                  <IconButton onClick={copyToClipboard} >
                    <MdContentCopy size={25} style={{ cursor: 'pointer', color: "#151418" }} />
                  </IconButton>
                </Tooltip>
              </div>
              <a
                className="btn GithubBtn btn-shadow"
                href="mailto:sebastiang1493@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Send Email  <FontAwesomeIcon icon={faEnvelope} />
              </a>
            </Email>
          </div>
          <Resume>
            <p> Download my resume ➜</p>
            <a
              className="btn GithubBtn btn-shadow"
              href="https://sgonzalez-personal-bucket.s3.amazonaws.com/CV_SebastianGonzalezRamirez.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Resume <FontAwesomeIcon icon={faDownload} />
            </a>
          </Resume>
        </ScrollAnimation>

      </div>
    </ContactWrapper>
  );
}

export default Contact;
