import React from "react";
import { stackList } from "../../data/ProjectData";
import {
  Image,
  Technologies,
  Tech,
  TechImg,
  TechName,
  ContactWrapper,
} from "./AboutElements";
import ScrollAnimation from "react-animate-on-scroll";
function About() {
  return (
    <ContactWrapper id="about">
      <div className="Container">
        <div className="SectionTitle">About Me</div>
        <div className="BigCard">
          <ScrollAnimation animateIn="fadeInLeft">
            <Image
              src="smallphoto.JPEG"

              alt="man-svgrepo"
            />
          </ScrollAnimation>
          <div className="AboutBio">
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                Hello! My name is <strong>Sebastian Gonzalez</strong>. I'm a Software Engineer & Computer Scientist with more than 8 years of experience in several areas of knowledge such as finance, health, education, property-tech, among others. Leading teams to sucess by taking in consideration the stakeholder's needs, smart business development, best technical practices and technologies to follow closely my client's vision.
              </p>
            </ScrollAnimation>

            <br /><br />

            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                For the past years I've been a <strong>Development Consultant </strong> and  <strong>Lead Software Engineer </strong> for Endava and recently for EPAM acting as a Technical Leader for mutiple clients. This represents design, planning, and execution of business needs by having good technical knowledge and team leadership skills.

                On the other hand, I've assumed the role of Discipline Lead which makes me accountable and  <strong>responsible</strong> of a couple of programs which demand great technical and soft skills. I also <strong> provide guidance and support </strong> for more than 10+ co-workers by sharing knowledge, discussing ideas and <strong>building</strong> a successful engineer mindset; Looking out for their strengths and weaknesses to set a clear path for their <strong>improvement</strong>.
              </p>
            </ScrollAnimation>

            <br /><br />

            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                Also, I have my own small business where my team and I provide technical assitance to different companies. This by providing <strong>software engineering services</strong> such as feature development, technical audit, security assitance, data modelling, among other services.

                We<strong> love</strong> what we <strong>do</strong> and we <strong>care</strong> about it.

              </p>

              {/* <hr /> */}
              <div className="tagline2">

                I have become confident using the following technologies:
              </div>
            </ScrollAnimation>


            <Technologies>
              {stackList.map((stack, index) => (
                <ScrollAnimation animateIn="fadeInLeft" key={index}>
                  <Tech key={index} className="tech">
                    <TechImg src={stack.img} alt={stack.name} />
                    <TechName>{stack.name}</TechName>
                  </Tech>
                </ScrollAnimation>
              ))}
            </Technologies>
          </div>

        </div>
      </div>
    </ContactWrapper>
  );
}

export default About;
