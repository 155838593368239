export const ExperiencesList = [

    {
        img: "siteimages/epam.png",
        company: "EPAM.com",
        title: "Lead Software Engineer & Consultant",
        description: ["• Software architecture design.", "• Scalable, reliable, and secure solutions.", "• Delivery of cutting-edge technology."],
        tech_stack: ["Python", "Flask", "Django", "FastAPI", "SQL", "Redis", "Jenkins", "Docker", "Microservices", "AWS", "Pytest"],
        company_site: "https://www.epam.com/",
    },

    {
        img: "siteimages/brand-06.png",
        company: "Endava.com",
        title: "Consulant & Development Lead",
        description:
            ["• Design - planning - execution of high-end software projects across multiple teams.", "• Leader of Colombia’s Python Community across Endavas delivery units.", "• Coach and mentor for Colombia's Python pool."],

        tech_stack: ["Python", "Flask", "Django", "Pytest", "SQL", "Jenkins", "Docker", "Microservices", "AWS", "Golang", "Redis"],
        company_site: "https://endava.com",
    },

    {
        img: "siteimages/suplio.png",
        company: "Suplio.co",
        title: "Senior Software Engineer & Co-CTO",
        description:
            ["• Colombia’s first PropTech startup", "• Automation & Pipelining for data and processes.", "• Head of the data department & Development Technical Leader"],
        tech_stack: ["Python", "Flask", "NodeJs", "React", "SQL", "Hive", "Monolith", "Bash"],
        company_site: "https://suplio.co",
    },
];